@font-face {
    font-family: 'Manrope';
    src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}

@media print {
    #root {
        display: none;
    }
}

:root {
    --main-font: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

body {
    margin: 0;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

textarea {
    font-family: var(--main-font);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button,
div {
    -webkit-tap-highlight-color: transparent;
}

input,
textarea {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;

    @media (max-width: 960px) {
        font-size: 14px;
    }
}

a {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: #6264ff;

    @media (max-width: 960px) {
        font-size: 14px;
    }
}
